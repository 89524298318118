import React from "react"
import { Link } from "gatsby"

import { withVideoCard } from "./withVideoCard"
import { routeResolver } from "../Wrappers/Helpers"
import { VideoDetails } from "./VideoDetails"
import { Card, Frame, Title, Content, DateTime, Button, Image, WishlistButton } from "./VideoCard.styles"
import { StyledRow } from "../../styled/Row"
import { StyledColumn } from "../../styled/Column"
import { StyledResponsive } from "../../styled/Responsive"
import { Icon } from "../Icon/Icon"

export const VideoCard = withVideoCard(
  ({ title, date, content, related, hidden, handleClick = () => false, handleSavedItem, existsInSavedItems, isLoggedIn, ...props }: Props): JSX.Element => (
    <Card shadow={"medium"} {...props}>
      <VideoDetails {...props} hidden={hidden} muted={false} playPause={true} ratio={`16/9`} playOnClick={false} onClick={handleClick} />
      <Frame {...props}>
        <Title {...props}>{title}</Title>
        {date && (
          <DateTime>
            {new Intl.DateTimeFormat(`en-AU`, {
              day: `2-digit`,
              month: `long`,
              year: `numeric`,
              timeZone: `Australia/Melbourne`,
            }).format(Date.parse(`${date}`))}
          </DateTime>
        )}
        <Content {...props}>{content}</Content>
        <StyledRow>
          <StyledColumn position={"left"} width={"md:w-2/3"}
          >
            {related && (
              <Button theme={`primary`} colour={`outline`} size={`small`} as={Link} to={routeResolver({ item: related })}>
                View {related?._type}
              </Button>
            )}
          </StyledColumn>
        </StyledRow>
      </Frame>
      {
        isLoggedIn &&
        <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
          <Icon name={`heart`} size={`ml`} />
        </WishlistButton>
      }
    </Card>
  )
)

export interface Props {
  size?: string
  horizontal?: boolean
  hidden?: boolean
  image?: any
  url?: string
  title?: string
  content?: string
  date?: Date
  related?: any
  poster?: any
  handleClick?: (video: any) => void
  handleSavedItem?: (any) => void
  existsInSavedItems?: boolean
  isLoggedIn?: boolean
}
