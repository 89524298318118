import React, { useMemo } from "react"
import { useApp } from "../../hooks/useApp"
import { useExistsInSavedItems, useToggleSavedItemsProduct } from "../../hooks/useSavedItems"

export const withVideoCard = Component =>
  React.memo(({ name = "VideoCard", onClick, ...props }) => {
    const handleClick = video => onClick(video)

    const { addToSavedItems, deleteFromSavedItems } = useToggleSavedItemsProduct()

    const { existsInSavedItems } = useExistsInSavedItems()
    const isExistsInSavedItems = existsInSavedItems(props?.id)

    const handleSavedItem = e => {
      e.preventDefault()
      if (isExistsInSavedItems) {
        deleteFromSavedItems(props?.id)
      } else {
        const link = props?.url
        const image = props?.poster.images.fallback.src
        addToSavedItems({ ...props, type: "video", link: link, image: { src: image } })
      }
    }

    const { customer } = useApp()
    const isLoggedIn = customer != null

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          handleClick={handleClick}
          handleSavedItem={handleSavedItem}
          existsInSavedItems={isExistsInSavedItems}
          isLoggedIn={isLoggedIn}
        />
      ),
      [onClick, isExistsInSavedItems, handleSavedItem, customer]
    )
  })
