import tw, { styled } from "twin.macro"

import { H1 } from "../../styled/Text"

export { StyledContainer as Container } from "../../styled/Container"
export { StyledGridColumn as GridColumn } from "../../styled/Grid"

export const Title = styled(H1)`
  ${tw`text-center text-xl lg:text-3xl mb-8 md:mb-12 font-bold`}
`
