import React from "react"

import { withVideoListing } from "./withVideoListing"
import { Container, GridColumn, Title } from "./VideoListing.styled"
import { getFluidGatsbyImage } from "../Wrappers/Helpers"
import { VideoCard } from "./VideoCard"
import { Grid } from "../Grid/Grid"

export const VideoListing = withVideoListing(
  ({ videos, title, member, handleActive, handleUpgrade }: Props): JSX.Element => (
    <>
      <Container topSpacing={`md`} width={`xl`}>
        <Title>{title}</Title>
      </Container>

      {videos?.edges?.length ? (
        <Container condense={`<md`} spacing={`lg`} width={`xl`}>
          <Grid>
            {videos?.edges?.map(({ node }) => (
              <GridColumn key={node?.id?.toString()} width={`md:w-1/2 lg:w-1/3`}>
                <VideoCard
                  size={`full`}
                  content={node?.summary}
                  date={node?.attributes?.publishedAt && new Date(node?.attributes.publishedAt)}
                  poster={node?.image && getFluidGatsbyImage(node?.image, { maxWidth: 800 })}
                  title={node?.title}
                  url={node?.video?.asset?.url || node?.videoUrl}
                  related={node?.related}
                  hidden={true}
                  onClick={node?.related?._type === "event" && !member?.membership?.status ? () => handleUpgrade(true) : () => handleActive(node)}
                  id={node?.id?.toString()}
                />
              </GridColumn>
            ))}
          </Grid>
        </Container>
      ) : null}
    </>
  )
)

export interface Props {
  videos: Array<any>
  title?: string
  customer?: any
  handleActive?: (video: any) => void
  handleUpgrade?: (show: boolean) => void
}
