import React from "react"
import { graphql } from "gatsby"

import { VideoListing as Page } from "../components/Video/VideoListing"

export const query = graphql`
  query {
    page: sanityPageVideos {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
    videos: allSanityVideo(filter: { attributes: { publishedAt: { ne: null } } }, sort: { fields: attributes___publishedAt, order: DESC }) {
      edges {
        node {
          ...GatsbyVideoFragment
        }
      }
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Page {...props} {...data} />
