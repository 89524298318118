import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

import { H4, P } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"
import { StyledButton } from "../../styled/Button"

export const Card = styled(StyledCard)`
  ${({ size, horizontal }) => (size === "large" && !horizontal ? tw`p-5` : null)}
  ${({ horizontal }) => (horizontal ? tw`flex p-2 text-left` : null)}
`
export const Frame = tw.figcaption`mt-4`
export const Title = styled(H4)`
  ${tw`text-grey-dark mb-3 text-lg lg:text-lg`}
`
export const Content = styled(P)`
  ${tw`text-grey-mid leading-tight text-base lg:text-base`}
`
export const DateTime = styled.time`
  ${tw`block font-bold text-xxs leading-none tracking-wide uppercase text-grey-mid mb-3`}
`
export const Button = tw(StyledButton)`mt-4 inline-block`

export const Image = tw(GatsbyImage)`inline-block w-full pb-aspect-square h-0 mb-3 rounded-md group-hover:shadow-lg`
export const WishlistButton = styled.button`
  ${tw`absolute top-8 right-8 focus:outline-none hover:text-pink z-2`}
  ${({active}) => active ? tw`text-pink` : tw`text-white`}
`