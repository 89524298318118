import React, { useMemo } from "react"

import { useApp } from "../../hooks/useApp"

export const withVideoListing = Component =>
  React.memo(({ name = "VideoListing", ...props }) => {
    const { member, setActiveVideo, activePopup, setActivePopup, setRedirectUrl } = useApp()

    const handleUpgrade = show => {
      setActivePopup(show)
      setRedirectUrl(`/videos`)
    }

    Component.displayName = name
    return useMemo(() => <Component {...props} member={member} handleActive={setActiveVideo} handleUpgrade={handleUpgrade} />, [member, activePopup])
  })
